import constants from "@/constants";

export default {
  methods: {
    getTextByStatus(status) {
      switch (status) {
        case constants.orderStatus.cancelled:
          return this.$t("order_status.cancelled");
        case constants.orderStatus.waiting:
          return this.$t("order_status.waiting");
        case constants.orderStatus.delivering:
          return this.$t("order_status.delivering");
        case constants.orderStatus.successfulDelivery:
          return this.$t("order_status.successful_delivery");
        case constants.orderStatus.completed:
          return this.$t("order_status.completed");
        case constants.orderStatus.waitingDelivery:
          return this.$t("order_status.waiting_delivery");
        case constants.orderStatus.pickUpFailed:
          return this.$t("order_status.pickup_failed");
        case constants.orderStatus.refundingToSender:
          return this.$t("order_status.refunding_to_sender");
        case constants.orderStatus.refundedToSender:
          return this.$t("order_status.refunded_to_sender");
        case constants.orderStatus.parcelWeight:
          return this.$t("order_status.compensation_order");
        case constants.orderStatus.orderReweighed:
          return this.$t("order_status.order-re-weighed");
        case constants.orderStatus.failDelivery:
          return this.$t("order_status.fail_delivery");
        default:
          return "";
      }
    },
  },
};
