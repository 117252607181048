import { BaseService } from './baseService'
import apis from './apis'

export class ReportService extends BaseService {
  static async wayBill(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.way_bill,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async reportFiles(url) {
    try {
      const response = await this.requestFile({ auth: true }).get(url, {
        responseType: 'arraybuffer'
      })
      return response
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
